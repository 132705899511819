



































































































































































































































































































































































import mixins from 'vue-typed-mixins'
import baseMixin from '@/mixins/baseMixin'
import QuantumChart, {QuantumChartChooser, QuantumViewChanger, QuantumDesignerSwitcher} from '@stackdc/quantum-charts'
import {Setting} from '@stackdc/quantum-charts/lib/src/types/shared'
import '@stackdc/quantum-charts/lib/main.css'
import { currentPivotKeyPrefix } from '@/constants'
import { quantumChartReportService } from '@/services'
import { QuantumChartReport } from '@/types/QuantumChartReportServiceType'
import { cloneDeep } from 'lodash'

const generateInitialSetting = () => ({
    chartView: false,
    chartOption: 'bar',
    showFieldChooser: false,
    isReadOnly: false
})

const generateInitialLoadedSetting = () => ({
    name: '',
})

function generateInitialReportForm(source: string) {
    return {
        form: {
            source,
            name: '',
            isPublic: false,
            isOwned:  true
        },
        errors: {} as Record<string,string>,
        upsertDialog: false,
        deleteDialog: false,
        success: false,
        loading: false,
        deleteReport: {} as QuantumChartReport
    }
}

function generateInitialQuantumChartReports() {
    return {
        owned: [] as QuantumChartReport[],
        public: [] as QuantumChartReport[],
        loading: false
    }
}

export default mixins(baseMixin).extend({
    props: ['searchOption', 'page', 'onDataLoad'],
    components: {
        QuantumChart,
        QuantumChartChooser,
        QuantumViewChanger,
        QuantumDesignerSwitcher
    },
    data() {
        const currentPivotSettingKey = `${this.page}_${currentPivotKeyPrefix}`
        return ({
            quantumChartReports: generateInitialQuantumChartReports(),
            currentPivotSettingKey,
            currentFormRelatedKey: `${currentPivotSettingKey}_form`,
            resetForm:{
                dialog:false,
                success: false,
            },
            setting: generateInitialSetting() as Setting,
            loadedSetting: generateInitialLoadedSetting(),
            reportForm: generateInitialReportForm(this.page),
            loadReportMenu: false,
            pivotState: {},
            qchartRef: null as any
        })
    },
    async mounted() {
        this.qchartRef = this.$refs.qchart as any
        const currentPivotState = sessionStorage.getItem(this.currentPivotSettingKey)
        
        setTimeout(() => {
            this.qchartRef.loadState(JSON.parse(currentPivotState || '{}'))
        }, 3000)
        await this.getReports()
        this.loadReportRelatedData()
    },
    methods: {
        async getReports() {
            try{ 
                this.quantumChartReports = generateInitialQuantumChartReports()
                this.quantumChartReports.loading = true

                const resp = await quantumChartReportService.getReports(this.page)
                resp.forEach( report => {
                    if(report.isOwned) {
                        this.quantumChartReports.owned.push(report)
                    } else {
                        this.quantumChartReports.public.push(report)
                    }
                    
                })

                this.quantumChartReports.loading = false
            } catch(e) {
                this.quantumChartReports.loading = false
            }
        },
        async onSaveReport() {
            this.reportForm.loading = true
            this.reportForm.errors = {}
            this.reportForm.success = false

            try{
                const settings = { pivotState: this.pivotState, settings: this.setting, searchOption: this.searchOption}
                await quantumChartReportService.upsertReport({...this.reportForm.form, settings})
                await this.getReports()
                this.reportForm.success = true
            } catch (e) {
                if(this.axios.isAxiosError(e) && e?.response?.status == 400 && e.response.data && e.response.data.errors) {
                    for (const [key, value] of Object.entries(e.response.data.errors)) {
                        this.reportForm.errors[key] = value as string
                    }
                    
                    this.reportForm.errors.General = 'There are errors that require your attention'
                } else {
                    this.reportForm.errors.General = 'We\'re sorry, but we can\'t process your request at this time. Please try again later.'
                }
            }
            this.reportForm.loading = false
        },
        onLoadReport(quantumChartReport: QuantumChartReport) {
            this.$emit('onBeforeQuantumChartReportLoad')
            const quantumChartReportCopy = cloneDeep(quantumChartReport)
            if(quantumChartReportCopy.settings.searchOption) {
                this.$emit('onSearchOptionChange', quantumChartReportCopy.settings.searchOption, true)
            }
            const {name, settings}  = quantumChartReportCopy
            this.reportForm.form.name = name
            this.setting = settings.settings
            this.loadedSetting.name = name
            setTimeout(() => {
                this.qchartRef.loadState(settings.pivotState)
            }, 2000)
            this.loadReportMenu = false
            this.cacheReportRelatedData()
        },
        onResetReportForm() {
            let formCopy = this.reportForm.form
            let isSuccess = this.reportForm.success

            this.reportForm = generateInitialReportForm(this.page)
            if(isSuccess) {
                this.loadedSetting.name = formCopy.name
            }
            this.reportForm.form = formCopy
        },
        onResetResetFormState() {
            this.resetForm.dialog = false
            this.resetForm.success = false
        },
        onChooseDeleteReport(quantumChartReport: QuantumChartReport){
            this.loadReportMenu = false
            this.reportForm.deleteReport = quantumChartReport
            this.reportForm.deleteDialog = true
        },
        async onDeleteReport() {
            this.reportForm.loading = true
            this.reportForm.errors = {}
            this.reportForm.success = false

            try{ 
                await quantumChartReportService.deleteReport(this.reportForm.deleteReport)
                this.reportForm.success = true
                await this.getReports()
            } catch(e) {
                this.reportForm.errors.General = 'There Are errors that require your attention'
            }
            this.reportForm.loading = false
        },
        onResetQuantumChart() {
            this.qchartRef.loadState(null)
            this.setting = generateInitialSetting()
            this.loadedSetting = generateInitialLoadedSetting()
            this.reportForm = generateInitialReportForm(this.page)
            this.resetForm.success = true
            sessionStorage.removeItem(this.currentFormRelatedKey)
        },
        cacheReportRelatedData() {
            sessionStorage.setItem(this.currentFormRelatedKey, JSON.stringify(this.reportForm))
        },
        loadReportRelatedData() {
            const reportForm = sessionStorage.getItem(this.currentFormRelatedKey)
            if(reportForm) {
                this.reportForm = JSON.parse(reportForm)
                this.loadedSetting = {
                    name:  this.reportForm.form.name
                }
            }
        },
        onExportCallback(wb: any) {
            if(this.searchOption.searchFilters.length < 1) {
                return
            }
            
            const sheet = wb.addWorksheet('Search Filters')

            sheet.columns = [
                { header: 'Field', key: 'field', width: 10},
                { header: 'Operator', key: 'operator', width: 32 },
                { header: 'Value', key: 'value', width: 10 },
                { header: 'Operand', key: 'logic', width: 10 }
            ]
            sheet.getRow(1).font = { name: 'Calibri', size: 11, bold: true } 


            for(let i = 0; i< this.searchOption.searchFilters.length; i++) {
                let current = this.searchOption.searchFilters[i]
                let row = sheet.getRow(i+2)
                sheet.columns.forEach( (column: any) => {
                    const currentKey = column['key']
                    if(currentKey) {
                        row.getCell(currentKey).value = current[currentKey]
                    }
                    
                })
            }

            // format sheet
            sheet.columns.forEach( (column: any) => {
                let maxLength = 0
                if(column && column['eachCell']) {
                    column['eachCell']({ includeEmpty: true }, (cell: any)  => {
                        let columnLength = cell.value ? cell.value.toString().length : 10
                        if (columnLength > maxLength ) {
                            maxLength = columnLength
                        }
                    })
                    column.width = maxLength < 10 ? 10 : maxLength
                }
            })
        }
    },
    computed: {
        exportFileName(): string { 
            return `${this.loadedSetting.name || `${this.page}_quantum_report_`}_${Date.now()}`
        }
    }
})
