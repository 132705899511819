



















import Vue from 'vue'

export default Vue.extend( {
    props: {
        page:String,
        loading: Boolean,
        isMapConfigured: Boolean
    },
})
