<template>
    <div class="d-flex flex-column">
        <pagination
            :pageIndex="pageIndex"
            :pageSize="pageSize"
            :pages="pages"
            :onSearchOptionChange="onSearchOptionChange"
            :sizes="sizes"
            :loading="loading"
        >
            <v-col
                md="1"
                pb="0"
                v-if="enableEditor"
            >
                <v-switch
                    :value="editorView"
                    @change="update('editorView', $event)"
                    inset
                    :label="`${editorView ? 'Editor On' : 'Editor Off'}`"
                ></v-switch>
            </v-col>
            <slot></slot>
        </pagination>

        <div>
            <v-data-table
                :headers="headers"
                :items="records"
                :items-per-page="pageSize"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                hide-default-footer
                :server-items-length="totalRecords"
                fixed-header
                :loading="loading"
                :height="height"
                :disable-sort="editorView"
                v-sortable-table="{onEnd: this.sortTheHeadersAndUpdateTheKey}"
                :key="vdataTableKey"
            >
                <template v-slot:top>
                    <div
                        class="d-flex mb-2 justify-end"
                        v-if="crudConfig.isCreateable"
                    >
                        <v-btn
                            color="secondary"
                            @click="createItem()"
                        >
                            {{ crudConfig.createPlaceHolderOverride || "New Item"}}
                        </v-btn>
                    </div>
                </template>

                <!-- Headers -->
                <template
                    v-for="field in headers"
                    v-slot:[`header.${field.value}`]="{header}"
                >
                    <v-btn
                        v-if="editorView"
                        :key="field.value"
                        icon
                        style="border-radius: 50% !important;"
                        @click="onColumnVisibleClick(header.text)"
                    >
                        <v-icon :class="{'green--text darken-3--text': !hiddenCols.includes(header.text), 'red--text darken-3--text': hiddenCols.includes(header.text)}">{{ hiddenCols.includes(header.text) ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                    </v-btn>
                    {{ header.text }}
                </template>

                <!-- <template v-slot:header="{ props }">
                    <thead class="v-data-table-header">
                        <tr>
                            <th v-for="head in props.headers" :key="head.text" class="text-start text-left primary--text bigger sortHandle sortable">{{ head.text }}</th>
                        </tr>
                    </thead>
                </template> -->

                <!-- modify data formatting -->
                <template
                    v-for="(column,i) in getConfig(fields)"
                    v-slot:[`item.${column.name}`]=" { item }"
                >
                    <router-link
                        v-if="column.method=='linkSelf'"
                        :to="`${column.url}/${item[column.name]}`"
                        :key="i"
                    >
                        <div
                            class="quicklink"
                            :inner-html.prop="item[column.name] | highlight(highlightText, column, keywordSearchPlaceholder)"
                        ></div>
                    </router-link>

                    <button
                        v-else-if="column.method=='quickFilter'"
                        @click="quickFilter(column.name,item[column.name])"
                        class="quicklink"
                        :key="i"
                    >
                        <div :inner-html.prop="item[column.name] |  highlight(highlightText, column, keywordSearchPlaceholder)"></div>
                    </button>

                    <div
                        v-else-if="column.method=='formatNumber'"
                        :key="i"
                    >
                        <div :inner-html.prop="item[column.name] | formatNumber|  highlight(highlightText, column, keywordSearchPlaceholder)"></div>
                    </div>

                    <div
                        v-else-if="column.method=='formatCurrency'"
                        :key="i"
                    >
                        <div :inner-html.prop="item[column.name] | formatCurrency|  highlight(highlightText, column, keywordSearchPlaceholder)"></div>
                    </div>

                    <div
                        v-else-if="column.method=='formatPercentage'"
                        :key="i"
                    >
                        <div :inner-html.prop="item[column.name]?`${item[column.name]}%` : '' | highlight(highlightText, column, keywordSearchPlaceholder)"></div>
                    </div>

                    <div
                        v-else-if="column.method=='formatDate'"
                        :key="i"
                    >
                        <div :inner-html.prop="item[column.name] | formatDate|  highlight(highlightText, column, keywordSearchPlaceholder)"></div>
                    </div>

                    <div
                        v-else-if="column.method=='formatBoolean'"
                        :key="i"
                    >
                        <div :inner-html.prop="item[column.name] | formatBoolean|  highlight(highlightText, column, keywordSearchPlaceholder)"></div>
                    </div>

                    <div
                        v-else-if="column.method=='quickFilterList'"
                        :key="i"
                    >
                        <span
                            v-for="(currentItem, idx) in item[column.name]"
                            :key="idx"
                        >
                            {{ idx == 0 ? '' : ', ' }}
                            <button
                                @click="quickFilter(column.name, currentItem.trim())" 
                                class="quicklink" 
                            >
                                <div :inner-html.prop="currentItem.trim() |  highlight(highlightText, column, keywordSearchPlaceholder)"></div>
                            </button>
                        </span>
                    </div>

                    <div
                        v-else
                        :key="i"
                    >
                        <div :inner-html.prop="item[column.name] |  highlight(highlightText, column, keywordSearchPlaceholder)"></div>
                    </div>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                        v-if="crudConfig.isUpdateable"
                    >
                        mdi-pencil
                    </v-icon>

                    <v-icon
                        small
                        @click="deleteItem(item)"
                        v-if="crudConfig.isDeleteable"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/lookup/Pagination.vue'
import { EventBus, ON_SEARCH_PAGE_CREATE, ON_SEARCH_PAGE_DELETE, ON_SEARCH_PAGE_EDIT } from '@/eventbus'
import { debounce } from 'lodash'
import sanitizeHtml from 'sanitize-html'
import Sortable from 'sortablejs'
import Vue from 'vue'

export default Vue.extend({
    components: {
        Pagination
    },
    props:['records','totalRecords','pageIndex','pageSize','fields','highlightText', 'loading', 'keywordSearchPlaceholder', 'order', 'crudConfig', 'pageName', 'height', 'enableEditor', 'hiddenCols', 'searchFilters' , 'editorView', 'headerOrder'],
    data() {
        return {
            sortBy: this.fields.find(field => field.alias === (this.order ?? '').replace(' desc', ''))?.name ?? '',
            sortDesc: (this.order ?? '').includes(' desc'),
            sizes:[
                {
                    text: '50',
                    value: 50
                },
                {
                    text: '100',
                    value: 100
                },
                {
                    text: '500',
                    value: 500
                },
                {
                    text: '1000',
                    value: 1000
                },
                {
                    text: '10000',
                    value: 10000
                }
            ],
            debouncedOnSearchOptionChange: () => null,
            vdataTableKey: Date.now()
        }
    },
    created() {
        if(window.vDataTableSortable) {
            window.vDataTableSortable.destroy()
            window.vDataTableSortable = null
        }
        this.debouncedOnSearchOptionChange = debounce(this.onSearchOptionChange, 500).bind(this)
    },
    methods: {
        sortTheHeadersAndUpdateTheKey(evt) {
            const tmpFields = [...this.headers]
            const oldIndexFieldCopy = tmpFields[evt.oldIndex]

            tmpFields.splice(evt.oldIndex, 1)
            tmpFields.splice(evt.newIndex, 0, oldIndexFieldCopy)
            
            this.updateHeaderOrder(tmpFields)
        },
        updateHeaderOrder(headers) {
            const newHeaderOrder = {}

            for(let i = 0; i < headers.length; i++) {
                newHeaderOrder[headers[i].text] = i + 1
            }

            this.update('headerOrder', newHeaderOrder)
            this.vdataTableKey = Date.now()
        },
        update(name, val){
            this.$emit(`update:${name}`,val)
        },
        onSearchOptionChange(searchOption) {
            this.$emit('onSearchOptionChange', searchOption, true)
        },
        quickFilter(...arg) {
            this.$emit('quickFilter',arg)
        },
        getConfig(fields) {
            return fields.filter(o=>typeof o.dataTableOrder === 'number')
        },
        createItem() {
            EventBus.$emit(ON_SEARCH_PAGE_CREATE, this.pageName)
        },
        editItem(item) {
            EventBus.$emit(ON_SEARCH_PAGE_EDIT, this.pageName, item)
        },
        deleteItem(item) {
            EventBus.$emit(ON_SEARCH_PAGE_DELETE, this.pageName, item)
        },
        onColumnVisibleClick(header) {
            const newCols = [...this.hiddenCols]
            const idx = newCols.indexOf(header) 
            
            if(idx > -1) {
                newCols.splice(idx, 1)
            } else {
                newCols.push(header)
            }
            
            this.update('hiddenCols', newCols)
        }
    },
    watch: {
        sortString(newValue) {
            this.debouncedOnSearchOptionChange({ order: newValue})
        },
        editorView(newValue) {
            if(!newValue) {
                this.updateHeaderOrder(this.headers)
            }
        }
    },
    filters: {
        highlight(haystack, needle, column, keywordSearchPlaceholder) {
            needle = (needle ?? '').replace(/[*]/g, '').replace(/[.]/, '\\.')
            const regex = new RegExp(needle, 'ig')
            const applyHighight = (v)=>`<span class="yellow lighten-3">${ v && sanitizeHtml(v)}</span>`
            return  needle && haystack && keywordSearchPlaceholder.toLowerCase().includes(column.alias.toLowerCase()) ? haystack.replace(regex, applyHighight) :  haystack && sanitizeHtml(haystack)
        },
    },
    computed: {
        sortString() {
            if(!this.sortBy) return ''

            const sortBy = this.fields.find(o=>o.name===this.sortBy).alias ?? ''
            return `${sortBy}${this.sortDesc ? ' desc' : ''}`
        },
        pages() {
            return Math.ceil( this.totalRecords / this.pageSize)
        },
        headers() {
            const generatedHeaders = this.fields
                .filter(o=>o.dataTableOrder!==undefined && (this.editorView || !(this.hiddenCols ?? []).includes(o.alias)))
                // @ts-ignore
                .sort((a,b)=>{
                    const aOrder = this.headerOrder[a.alias] || 99999
                    const bOrder = this.headerOrder[b.alias] || 99999
                    return aOrder - bOrder
                })
                .map(o=>({
                    text:o.alias,
                    value:o.name,
                    method:o?.method,
                    url:o?.url,
                    class:`text-left ${ o.sortable == undefined || o.sortable ? 'primary--text' : ''} bigger sortHandle`,
                    sortable: o.sortable == undefined || o.sortable
                }))

            if (this.crudConfig.isUpdateable || this.crudConfig.isCreateable || this.crudConfig.isDeleteable) {
                generatedHeaders.push({
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    class: 'text-center bigger'
                })
            }
            return generatedHeaders
        }
    },
    directives: {
        'sortable-table': {
            inserted: (el, binding) => {
                window.vDataTableSortable = Sortable.create(el.querySelector('tr'), { onEnd: binding.value.onEnd, handle: '.sortHandle' })
            },
            // update: (el, binding) => {
            //     console.log(el.querySelector('tr'))
            //     window.vDataTableSortable.destroy()
            //      window.vDataTableSortable = Sortable.create(el.querySelector('tr'), { onEnd: binding.value.onEnd, handle: '.sortHandle' })
            //     setTimeout(()=>{
            //         window.vDataTableSortable = Sortable.create(el.querySelector('tr'), binding.value ? { onEnd: binding.value.onEnd, handle: '.sortHandle', disabled : !binding.value.editorView } : { disabled : true})
            //     }, 500)
                
            // }
        },
    },
})
</script>
<style>
.quicklink {
    text-align: left;
    color:#4CAF50;
}
.quicklink:hover{
    text-decoration: underline;
}
.bigger > span {
    font-size: 14px;
}
th,td {
    padding:8px !important;
}
th{
    vertical-align: top !important;
    white-space: nowrap;
}
</style>
