<template>
    <div>
        <!-- Action Buttons-->
        <v-row class="pl-4 mt-2 action-btns">
            <!-- <v-col cols="auto" class="px-1" style="align-self:center" v-if="shouldShowAltSearch" >
                <v-btn 
                
                    color="secondary" 
                    @click="update('isAdvancedSearch',!isAdvancedSearch)"    
                >
                    <v-icon left>mdi-filter</v-icon>
                    {{isAdvancedSearch ? "Keyword Search": "Advanced Search"}}
                </v-btn>
            </v-col> -->
            <slot></slot>
            <v-col
                cols="auto"
                class="px-1 py-sm-0"
                style="align-self:center"
            >
                <v-btn
                    color="secondary"
                    @click="$emit('export')"
                    v-if="canUseExportFeature"
                >
                    <v-icon left>mdi-export-variant</v-icon>
                    Export
                </v-btn>
            </v-col>
            <v-col
                cols="auto"
                class="px-1 py-sm-0"
                style="align-self:center"
                v-for="importable in importables"
                :key="importable.id"
            >
                <v-btn 
                    color="secondary"
                    :loading="importConfig.isSelecting"
                    @click="onImportClick(importable)"
                    v-if="isFeatureAccessible(importable.requiredPermissions, $store.getters.user.permissions)"
                >
                    <v-icon left>mdi-import</v-icon>
                    Import
                </v-btn>
                <input
                    :ref="`uploader_${importable.id}`"
                    class="d-none"
                    type="file"
                    accept=".xlsx"
                    @change="onImportFileChanged"
                >
            </v-col>
        </v-row>

        <!-- Temp remove Mobile Advanced Search?-->
        <!-- <span v-if="shouldShowMobileAdvancedSearch">
            <v-row
                class="pl-2" 
                v-for="(filter, i) in searchFilters"
                :key="'filter-' + filter.id"
                align="center"
            >
                <v-col md="3" cols="12" class="py-0">
                    <v-autocomplete
                        :value="filter.field"
                        @input="onFilterChange($event, filter)"
                        return-object
                        :items="fields"
                        item-text="alias"
                        label="Field"
                    ></v-autocomplete>
                </v-col>

                <v-col md="2" cols="12" class="py-0">
                    <v-select
                        v-model="filter.operator"
                        :items="operators[filter.nonFilterFields.type]"
                        label="Operator"
                        @change="onFilterDropdownChange(filter.value, filter)"
                    ></v-select>
                </v-col>

                <v-col md="3" cols="12" class="py-0">
                    <v-text-field
                        v-model="filter.value"
                        :label="getFilterLabel(filter)"
                        v-if="!isFilterDate(filter)"
                        clearable
                        @keydown.enter="$emit('onSearch')"
                    ></v-text-field>

                    <v-menu
                        v-model="filter.nonFilterFields.datePickerMenu"
                        :close-on-content-click="false"
                        max-width="290px"
                        min-width="auto"
                        v-if="isFilterDate(filter)"
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="filter.value"
                                clearable
                                :label="getFilterLabel(filter)"
                                v-bind="attrs"
                                v-on="on"
                                @change="onFilterDropdownChange($event, filter)"
                                @click:clear="filter.value = null;filter.nonFilterFields.dateValue=null"
                                @keydown.enter="$emit('onSearch')"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="filter.nonFilterFields.dateValue"
                            @change="onFilterDatePickChange(filter)"
                            :range="filter.operator.includes('between')"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col md="1" cols="3" class="py-0">
                    <v-select
                        v-model="filter.logic"
                        :items="['and', 'or']"
                    ></v-select>
                </v-col>

                <v-col md="3" cols="9" class="py-0">
                    <v-btn
                        color="green"
                        class="mr-2 white--text"
                        @click="addFilter"
                        :disabled="searchFilters.length > (maxSearchFilter-1)"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                        color="red"
                        class="mr-2 white--text"
                        @click="removeFilter(i)"
                        :disabled="searchFilters.length == 1"
                    >
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <v-btn 
                        color="secondary"
                        class="mr-2"
                        @click="clearFilter(i)"
                    >
                        <v-icon>mdi-eraser</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </span>
        <v-row class="pl-2" v-if="shouldShowMobileAdvancedSearch">
            <v-col cols="auto" class="px-1 pb-sm-0" style="align-self:center">
                <v-btn color="primary"
                       @click="$emit('onSearch')"
                >
                    <v-icon left>mdi-magnify</v-icon>
                    Search
                </v-btn>
            </v-col>

            <v-col cols="auto" class="px-1 pb-sm-0" style="align-self:center">
                <v-btn color="secondary"
                       @click="resetFilters"
                >
                    <v-icon left>mdi-restart</v-icon>
                    Reset
                </v-btn>
            </v-col>
        </v-row> -->
        
        
        <!-- removed: v-show="!shouldShowAltSearch | (shouldShowAltSearch && !shouldShowMobileAdvancedSearch)"> since mobile search is temp removed -->
        <v-row class="pl-2 keyword-search-container">
            <v-col
                md="6"
                cols="12"
                class="py-0"
            >
                <v-text-field
                    :value="keywords"
                    @input="update('keywords',$event)"
                    append-icon="mdi-magnify"
                    :label="keywordSearchPlaceholder"
                    clearable
                    @click:append="$emit('onSearch'); update('isAdvancedSearch', false)"
                    @click:clear="update('keywords', '');$emit('onSearch'); update('isAdvancedSearch', false)"
                    @keydown.enter="$emit('onSearch')"
                    @focus="update('isAdvancedSearch', false)"
                ></v-text-field>
            </v-col>
        </v-row>

        <!-- Import Dialogs-->
        <v-row justify="center">
            <v-dialog
                v-model="importConfig.showInvalidImportDialog"
                persistent
                max-width="700px"
            >
                <v-card :key="importConfig.showInvalidImportDialog">
                    <v-card-title 
                        :style="{
                            backgroundColor: $vuetify.theme.currentTheme.error,
                            color: 'white',
                            display: 'flex',
                            gap: '10px'
                        }"
                    >
                        <v-icon
                            large
                            color="white"
                        >
                            mdi-alert
                        </v-icon>
                        Import {{ importConfig.selectedImportable.name }} : Invalid Template
                    </v-card-title>
                    <v-card-text class="pt-2 text--primary">
                        The file you selected doesn't use the correct template. Please ensure it has the following headers:
                    </v-card-text>
                    <v-card-text class="text--primary">                          
                        <span
                            v-for="(field, i) in importConfig.selectedImportable.fields"
                            :key="field.requestKey"
                        >
                            <span v-if="i!==0">, </span>
                            <span :style="{color: importConfig.headers.foundHeaders[sanitizeImportHeaderField(field.columnName)] ?  'primary' : $vuetify.theme.currentTheme.error}">{{field.columnName.trim()}}</span>
                        </span>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="onImportDataCancel()"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="importConfig.showValidImportDialog"
                persistent
                max-width="700px"
            >
                <v-card :key="importConfig.showValidImportDialog">
                    <v-card-title 
                        :style="{
                            backgroundColor: $vuetify.theme.currentTheme.primary,
                            color: 'white',
                            display: 'flex',
                            gap: '10px'
                        }"
                    >
                        <v-icon
                            large
                            color="white"
                        >
                            mdi-import
                        </v-icon>
                        Import {{ importConfig.selectedImportable.name }} : {{ importConfig.xlsxMetadata.source }}
                    </v-card-title>
                    <v-card-text class="pt-2 text--primary">
                        <div class="py-2">You are about to insert/update <b>{{ importConfig.xlsxMetadata.rowCount }} data</b>. Please note that only the following fields will be imported from the XLSX file:</div>
                    </v-card-text>
                    <v-card-text class="pt-2 text--primary">
                        <span
                            v-for="(field, i) in importConfig.selectedImportable.fields"
                            :key="field.requestKey"
                        >
                            <span v-if="i!==0">, </span>
                            <span>{{field.columnName.trim()}}</span>
                        </span>
                    </v-card-text>
                    <v-card-text v-if="importSuccess || importFailed">
                        <v-alert
                            prominent
                            :type="(importSuccess && 'success') || (importFailed && 'error')"
                            text
                        >
                            <v-row align="center">
                                <v-col class="grow">
                                    <div
                                        v-for="(importSuccessMessage, i) in importConfig.importSuccessMessages"
                                        :key="i"
                                    >
                                        {{ importSuccessMessage }}
                                    </div>

                                    <div
                                        v-for="(importFailedMessage, i) in importConfig.importFailedMessages"
                                        :key="i"
                                    >
                                        {{ importFailedMessage }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="onImportData()"
                            :loading="importConfig.importLoading"
                            v-if="!importSuccess && !importFailed"
                        >
                            Import
                        </v-btn>
                        <v-btn
                            text
                            @click="onImportDataCancel()"
                            :disabled="importConfig.importLoading"
                        >
                            {{importSuccess || importFailed ? 'Close' :  'Cancel'}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import mixins from 'vue-typed-mixins'
import AdvancedSearchMixin from '@/mixins/advancedSearchMixin'
import { isFeatureAccessible } from '@/utils'

export default mixins(AdvancedSearchMixin).extend({
    name: 'AdvancedSearch',
    // computed: {
    //     shouldShowMobileAdvancedSearch() {
    //         return this.isAdvancedSearch && this.shouldShowAltSearch
    //     },
    //     shouldShowAltSearch() {
    //         return this.$vuetify.breakpoint.smAndDown && !this.$vuetify.breakpoint.smAndUp
    //     }
    // },
    methods: {
        isFeatureAccessible
    }
})
</script>

