

















import mixins from 'vue-typed-mixins'
import GmapMixin from '@/mixins/gmapMixin'

export default mixins(GmapMixin).extend({
    props:['recordsLoading'],
})
