<template>
    <div class="pa-0 d-flex">
        <transition
            name="slide-x-transition"
            mode="out-in"
        >
            <v-sheet
                elevation="1"
                class="pt-6 pb-12 pr-4 pl-8"
                style="overflow: auto; width:300px"
                v-if="isAdvancedSearch"
                key="1"
            >
                <v-row class="justify-space-between align-center pa-3">
                    <v-btn 
                        color="primary"
                        @click="$emit('onSearch')"
                        small
                    >
                        <v-icon left>mdi-magnify</v-icon>
                        Search
                    </v-btn>
                    
                    <v-btn
                        color="secondary"
                        @click="resetFilters"
                        small
                    >
                        <v-icon left>mdi-restart</v-icon>
                        Reset
                    </v-btn>
                    
                    <v-btn
                        elevation="2"
                        fab
                        icon
                        small
                        color="primary"
                        style="border-radius: 20px !important;"
                        @click="update('isAdvancedSearch', !isAdvancedSearch)"
                    >
                        <v-icon dark>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-row>

                <v-row
                    v-for="(filter, i) in searchFilters"
                    :key="'filter-' + filter.id"
                    align="center"
                    class="pb-4"
                >            
                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <v-autocomplete
                            :value="filter.field"
                            @input="onFilterChange($event, filter)"
                            return-object
                            :items="searchFields"
                            item-text="alias"
                            label="Field"
                            dense
                        ></v-autocomplete>
                    </v-col>

                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <v-select
                            v-model="filter.operator"
                            :items="operators[filter.nonFilterFields.type]"
                            label="Operator"
                            @change="onFilterDropdownChange(filter.value, filter)"
                            dense
                        ></v-select>
                    </v-col>

                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <v-text-field
                            v-model="filter.value"
                            :label="getFilterLabel(filter)"
                            v-if="!isFilterDate(filter)"
                            clearable
                            @keydown.enter="$emit('onSearch')"
                            dense
                        ></v-text-field>

                        <v-menu
                            v-model="filter.nonFilterFields.datePickerMenu"
                            :close-on-content-click="false"
                            max-width="340px"
                            min-width="auto"
                            v-if="isFilterDate(filter)"
                            offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="filter.value"
                                    clearable
                                    :label="getFilterLabel(filter)"
                                    v-bind="attrs"
                                    v-on="on"
                                    @change="onFilterDropdownChange($event, filter)"
                                    @click:clear="filter.value = null;filter.nonFilterFields.dateValue=null"
                                    @keydown.enter="$emit('onSearch')"
                                    dense
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="filter.nonFilterFields.dateValue"
                                @change="onFilterDatePickChange(filter)"
                                :range="filter.operator.includes('between')"
                                width="340px"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>

                    
                    <v-col
                        cols="6"
                        class="py-0"
                    >
                        <v-select
                            label="Operand"
                            v-model="filter.logic"
                            :items="['and', 'or']"
                            dense
                        ></v-select>
                    </v-col>

                    <v-col
                        cols="6"
                        class="d-flex py-0 mb-4"
                    >
                        <v-btn
                            color="green"
                            class="mr-1 white--text"
                            @click="addFilter"
                            :disabled="searchFilters.length > (maxSearchFilter-1)"
                            small
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                            color="red"
                            class="mr-1 white--text"
                            @click="removeFilter(i)"
                            :disabled="searchFilters.length == 1"
                            small
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                        <!-- Seems redundant -->
                        <!-- <v-btn 
                            color="secondary"
                            class="mr-2 mt-2"
                            @click="clearFilter(i)"
                            small
                        >
                            <v-icon>mdi-eraser</v-icon>
                        </v-btn> -->
                    </v-col>
                </v-row>
            </v-sheet>
            <v-sheet
                elevation="2"
                v-else
                class="pt-6 pb-12 d-flex justify-center"
                key="2"
            >
                <v-btn
                    class="mx-2 pa-2"
                    elevation="2"
                    fab
                    icon
                    small
                    color="primary"
                    style="width: 50px; border-radius: 20px !important;"
                    @click="update('isAdvancedSearch', !isAdvancedSearch)"
                >
                    <v-icon>
                        mdi-filter-variant
                    </v-icon>
                </v-btn>
            </v-sheet>
        </transition>
    </div>
</template>

<script>
import mixins from 'vue-typed-mixins'
import AdvancedSearchMixin from '@/mixins/advancedSearchMixin'

export default mixins(AdvancedSearchMixin).extend({
    name: 'AdvancedSidebarSearch'
})
</script>
