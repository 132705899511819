import Vue from 'vue'
import { toAppDateFormat, toIsoDateFormat } from '@/utils'
import { WorkSheet, read, utils } from 'xlsx'
import { cloneDeep } from 'lodash'
import { genericErrorMessage } from '@/constants'
import { Filter, Importable, SearchConfigField } from '@/types/utilTypes'

type XlsxHeader = { columns: Record<string, number>, rowIndex: number, isValid: boolean, foundHeaders: Record<string,boolean> }

type ImportConfig = {
    selectedFile: File | null,
    isSelecting: boolean,
    showInvalidImportDialog: boolean,
    showValidImportDialog: boolean,
    selectedImportable: Importable,
    headers: XlsxHeader,
    xlsxMetadata: {
        fileName: string,
        rowCount: number,
        source: string
    },
    toBeImportedData: any[],
    importLoading: boolean,
    importFailedMessages: string[],
    importSuccessMessages: string[]
}

function generateImportConfig(): ImportConfig {
    return {
        selectedFile: null,
        isSelecting: false,
        showInvalidImportDialog: false,
        showValidImportDialog: false,
        selectedImportable: {
            id: '',
            name: '',
            api: '',
            fields: []
        },
        headers: { columns: {}, rowIndex: 0, isValid: false, foundHeaders: {} },
        xlsxMetadata: {
            fileName: '',
            rowCount: 0,
            source: ''
        },
        toBeImportedData: [],
        importLoading: false,
        importFailedMessages: [],
        importSuccessMessages: []
    }
}

export default Vue.extend({
    props: {
        isAdvancedSearch: {
            type: Boolean,
        },
        keywords: {
            type: String,
        },
        searchFilters: {
            type: Array as () => Filter[],
        },
        fields: {
            type: Array as () => SearchConfigField[],
        },
        keywordSearchPlaceholder: {
            type: String,
        },
        importables: {
            type: Array as () => Importable[],
        },
        canUseExportFeature: {
            type: Boolean,
        },
        hiddenCols: {
            type: Array as () => string[]
        }
    },
    data() {
        return {
            operators: {
                'string': [
                    'contains',
                    'exact match',
                    'in',
                    'ends with',
                    'starts with',
                    'is empty',
                    'is not empty',
                    'not contains',
                    'not match',
                    'not in'
                ],
                'number': [
                    'between',
                    'equals',
                    'greater than',
                    'less than',
                    'not equals',
                    'is empty',
                    'is not empty'
                ],
                'date': [
                    'between',
                    'equals',
                    'greater than',
                    'less than',
                    'not equals',
                    'is empty',
                    'is not empty'
                ],
                'boolean': [
                    'equals',
                    'not equals'
                ]
            } as Record<string, string[]>,
            placeholders: {
                'string': 'Enter search text',
                'number': 'Enter numeric value',
                'date': 'Enter date value (e.g. 01-Jan-2020)',
                'in': 'Enter comma or space delimited values (e.g. 123 abc 456) ',
                'not in': 'Enter comma or space delimited values (e.g. 123 abc 456) ',
                'between_number': 'Enter two numeric values separated by and (e.g. 1 and 100)',
                'between_date': 'Enter two dates separated by and (e.g. 01-Jan-2010 and 01-Dec-2020)',
                'is empty': ' ',
                'is not empty': ' '
            } as Record<string, string>,
            importConfig: generateImportConfig(),
            maxSearchFilter: 10
        }
    },
    watch:{
        isAdvancedSearch(){
            if(this.searchFilters.length===0 && this.isAdvancedSearch) this.searchFilters.push(this.createFilter())
        }
    },
    methods: {
        update(name: string, val:any){
            this.$emit(`update:${name}`,val)
        },
        resetFilters() {
            this.searchFilters.splice(0)
            this.searchFilters.push(this.createFilter())
            this.$emit('onSearch')
        },
        addQuickFilter(field: string, value: string){
            const filter = this.createFilter({columnFieldName: field, value})

            const shouldPopulateFirstEntry = this.searchFilters.length == 1 && !this.searchFilters[0].field && !this.searchFilters[0].operator && !this.searchFilters[0].value
            const index = shouldPopulateFirstEntry? 0 : this.searchFilters.findIndex((flt)=>flt.field === filter.field)

            
            if(index === -1) {
                if(this.searchFilters.length < this.maxSearchFilter) {
                    this.searchFilters.push(filter)
                } else {
                    // find nearest none populated filter or the first entry if all are populated
                    const unpopulatedIndex = this.searchFilters.findIndex( flt => !(flt.field && flt.value && flt.operator)) || 0
                    this.searchFilters.splice(unpopulatedIndex, 1, filter)
                }
            } else {
                this.searchFilters.splice(index, 1, filter)
            }

            this.update('isAdvancedSearch',true)
            this.$emit('onSearch')
        },
        addFilter() {
            this.searchFilters.push(this.createFilter())
        },
        getFilterLabel(filter: Filter) {
            const labelKeyByOperator = filter.nonFilterFields.type && !filter.operator.includes('empty') ?  `${filter.operator}_${filter.nonFilterFields.type}` : filter.operator

            return this.placeholders[labelKeyByOperator] || this.placeholders[filter.nonFilterFields.type]
        },
        clearFilter(i: number) {
            this.searchFilters.splice(i,1,this.createFilter())
        },
        removeFilter(i: number) {
            if(this.searchFilters.length===1) {
                this.searchFilters.splice(0)
                this.update('isAdvancedSearch',false)
                this.$emit('onSearch')
                return
            }
            this.searchFilters.splice(i,1)
        },
        createFilter({columnFieldName, value} = {columnFieldName: '', value: ''}) {
            const result = {
                id: Date.now().toString(),
                field: '',
                operator: '',
                value: '',
                logic: 'and',
                nonFilterFields: {
                    dateValue: '',
                    datePickerMenu: false,
                    type: ''
                }
            }

            if(columnFieldName) {
                const foundField = this.fields.find( field => field.name === columnFieldName)
                
                if(foundField) { 
                    result.field = foundField.alias
                    result.operator = foundField.defaultOperator
                    result.value = value
                    result.nonFilterFields.dateValue = toIsoDateFormat(value)
                    result.nonFilterFields.type = foundField.type
                }
            }

            return result
        },
        onFilterChange(currentValue: SearchConfigField, filter: Filter) {
            if(currentValue) {
                filter.field = currentValue.alias
                filter.operator= currentValue.defaultOperator
                filter.nonFilterFields.type = currentValue.type
            } else {
                filter.field = ''
                filter.operator = ''
                filter.nonFilterFields.type = ''
            }
            this.onFilterDropdownChange(filter.value, filter)
        },
        onFilterDatePickChange(filter: Filter){ 
            filter.nonFilterFields.datePickerMenu = false
            if(Array.isArray(filter.nonFilterFields.dateValue)) {
                const start = filter.nonFilterFields.dateValue[0]
                const end = filter.nonFilterFields.dateValue[1]

                filter.value = `${toAppDateFormat(start)} and ${toAppDateFormat(end)}`
            } else {
                filter.value=toAppDateFormat(filter.nonFilterFields.dateValue)
            }
            
        },
        isFilterDate(filter: Filter) {
            return filter.nonFilterFields.type == 'date'
        },
        onFilterDropdownChange(filterValue: string, filter: Filter) {
            if(filter.operator == 'between') {
                const [start, end] = (filterValue ?? '').split(' and ')
                const result  = []

                const startDate = toIsoDateFormat(start)
                const endDate = toIsoDateFormat(end)
                
                if(startDate) {
                    result.push(startDate)
                }

                if(endDate) {
                    result.push(endDate)
                }
                filter.nonFilterFields.dateValue = result
                filter.value = filterValue
                return
            }

            
            filter.nonFilterFields.dateValue = toIsoDateFormat(filterValue)
            filter.value = filterValue
        },
        onImportClick(importable: Importable) {
            const importRefKey = `uploader_${importable.id}`
            this.importConfig = generateImportConfig()

            // @ts-ignore
            this.$refs[importRefKey][0].value = null

            this.importConfig.isSelecting = true
            window.addEventListener('focus', () => {
                this.importConfig.isSelecting = false
            }, { once: true })

            // @ts-ignore
            this.$refs[importRefKey][0].click()
            this.importConfig.selectedImportable = importable
        },
        async onImportFileChanged(e : Event) {
            const target = <HTMLInputElement>e.target
            if(!target || !target?.files || !target?.files[0]) {
                return
            }

            this.importConfig.selectedFile = target.files[0]
            
            const workbook = read(await this.importConfig.selectedFile.arrayBuffer(), { raw: true, cellDates: true })
            const sheet = workbook.Sheets[workbook.SheetNames[0]]
            const xlsxArray = this.getXlsxArray(sheet)

            const headers = this.getXlsxHeader(xlsxArray, this.importableHeaders[this.importConfig.selectedImportable.id], void 0)
            this.importConfig.headers = headers
            
            if(headers.isValid) {
                const toBeImported = []
                for (let i = headers.rowIndex + 1; i < xlsxArray.length; i++) {
                    const entry: Record<string, any> = {}
                    this.importConfig.selectedImportable.fields.forEach(field=> {
                        entry[field.requestKey] = (xlsxArray[i][headers.columns[this.sanitizeImportHeaderField(field.columnName)]] || '')
                    })
                    toBeImported.push(entry)
                }
                            
                if(toBeImported.length > 0) {
                    this.importConfig.showValidImportDialog = true
                    this.importConfig.xlsxMetadata = {
                        fileName: this.importConfig.selectedFile.name,
                        rowCount: toBeImported.length,
                        source: this.importConfig.selectedFile.name
                    }
                    this.importConfig.toBeImportedData = toBeImported
                    return
                }
            }
            this.importConfig.showInvalidImportDialog = true

        },
        isMustHaveFieldsDefined(mandatoryIfModel: undefined | Record<string, any>): boolean {
            return mandatoryIfModel && mandatoryIfModel.mustHaveField
        },
        sanitizeImportHeaderField(unsanitizedField: string) {
            return (unsanitizedField || '').toString().replace(/\s/g, '').toLowerCase()
        },
        getXlsxHeader(xlsxArray: any[], requiredHeaders: string[], mandatoryIfModel: undefined | Record<string, any>): XlsxHeader {
            let foundHeaders: Record<string, boolean> = {}
            let mustHaveCounter = 0
            const mustHaveFieldsObject: Record<string, boolean> = {}

            if (this.isMustHaveFieldsDefined(mandatoryIfModel)) {
                for (let i = 0; i < mandatoryIfModel?.mustHaveFields.length; i++) {
                    const formattedMustHaveField = this.sanitizeImportHeaderField(mandatoryIfModel?.mustHaveFields[i])

                    mustHaveFieldsObject[formattedMustHaveField] = true
                }
            }
            
            // preserve the original required and formatted headers in order to keep it fresh every row change(because the mustHave logic replaces the requiredHeaders)
            if (xlsxArray.length > 1) {
                for (let r = 0; r < 20 && r < xlsxArray.length; r++) { // scan the first 20 rows to validate required headers
                    let requiredHeadersCopy = cloneDeep(requiredHeaders) 
                    const columns: Record<string, number> = {}
                    let foundHeaderCount = 0
                    foundHeaders = {}
                    

                    for (let c = 0; c < xlsxArray[r].length; c++) {
                        const columnName = this.sanitizeImportHeaderField(xlsxArray[r][c])
                        columns[columnName] = c

                        if (this.isMustHaveFieldsDefined(mandatoryIfModel) && mustHaveFieldsObject[columnName]) {
                            mustHaveCounter++
                        }
                    }

                    if (this.isMustHaveFieldsDefined(mandatoryIfModel) && mustHaveCounter === mandatoryIfModel?.mustHaveFields.length) {
                        requiredHeadersCopy = requiredHeadersCopy.concat(mandatoryIfModel.mandatoryIfFields)
                    }


                    for (let h = 0; h < requiredHeadersCopy.length; h++) {
                        const key = this.sanitizeImportHeaderField(requiredHeadersCopy[h])
                        if (Object.prototype.hasOwnProperty.call(columns, key)) {
                            foundHeaders[key] = true
                            foundHeaderCount++
                        }
                    }

                    if (foundHeaderCount === requiredHeadersCopy.length) {
                        return { columns, rowIndex: r, isValid: true, foundHeaders }
                    }
                    else if (foundHeaderCount > 0) {
                        break
                    }
                    
                }
            }

            
            return { columns: {}, rowIndex: 0, isValid: false, foundHeaders }

        },
        async onImportData() {
            try {
                this.importConfig.importLoading = true
                const response = await this.axios.post(this.importConfig.selectedImportable.api, {
                    'importData': this.importConfig.toBeImportedData
                })
                this.importConfig.importSuccessMessages = [response.data.message]
                this.$emit('onSearch')
                
            } catch (e) {
                if(this.axios.isAxiosError(e) && e.response?.status == 400) {
                    this.importConfig.importFailedMessages = this.createMessagesFromError(e.response.data.errors, this.importConfig.headers.rowIndex + 1)
                } else {
                    this.importConfig.importFailedMessages = [genericErrorMessage]
                }
            } finally {
                this.importConfig.importLoading = false
            }
        },
        onImportDataCancel() {
            this.importConfig = generateImportConfig()
        },
        getXlsxArray(sheet: WorkSheet) {
            const result: any[] = []

            if (!sheet['!ref']) {
                return result
            }
            const range = utils.decode_range(sheet['!ref'])
            for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
                const row = []
                for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
                    const nextCell = sheet[
                        utils.encode_cell({ r: rowNum, c: colNum })
                    ]


                    if (typeof nextCell === 'undefined') {
                        row.push(void 0)
                    } 
                    else if(nextCell.t === 'n') {
                        row.push(nextCell.v.toString())
                    }
                    else  {
                        row.push(nextCell.w)
                    }
                }

                result.push(row)
            }

            return result
        },
        createMessagesFromError(errors: Record<string,string>, startRowIndex = 0) {
            const messages = []
            for(const key in errors) {
                const startRowErrorIndex = key.indexOf('[') + 1
                const endRowErrorIndex = key.lastIndexOf(']')

                const row = parseInt(key.substring(startRowErrorIndex, endRowErrorIndex)) + 1 + startRowIndex
                if(!isNaN(row)) {
                    messages.push(`Row ${row} : ${errors[key]}`)
                }
                
            }

            return messages
        }

    },
    computed: {
        importableHeaders(): Record<string, string[]> {
            const result: Record<string, string[]> = {}

            this.importables.forEach(importable => {
                result[importable.id] = importable.fields.map( field => field.columnName)
            })

            return result
        },
        importSuccess(): boolean {
            return this.importConfig.importSuccessMessages.length > 0
        },
        importFailed(): boolean {
            return this.importConfig.importFailedMessages.length > 0
        },
        searchFields(): SearchConfigField[] {
            const hiddenColsMap = this.hiddenCols.reduce((acc, col) => {
                acc[col] = true
                return acc
            }, {} as Record<string, boolean>)

            return this.fields.filter(field => !hiddenColsMap[field.alias] || field.showPersistently)
        }
    },
})