












































import Vue from 'vue'

export default Vue.extend({
    name: 'Pagination',
    props: ['pageIndex', 'pageSize', 'pages', 'onSearchOptionChange', 'sizes', 'loading'],
    data() {
        return {
        }
    },
})
