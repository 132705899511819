























































































































































































































































































































































































































import AdvancedSearch from '@/components/lookup/AdvancedSearch.vue'
import AdvancedSidebarSearch from '@/components/lookup/AdvancedSidebarSearch.vue'
import DataTable from '@/components/lookup/DataTable.vue'
import GMapPin from '@/components/lookup/GMapPin.vue'
import UnconfiguredGmapAlert from '@/components/UnconfiguredGmapAlert.vue'
import { EventBus, ON_SHOW_MAP, TRIGGER_SEARCH } from '@/eventbus'
import { reportService } from '@/services'
import { GmapMarker, GmapRecord } from '@/types/gmapMixinType'
import { Report } from '@/types/ReportServiceType'
import { GmapRecordSetting, StackGmapIconSetting } from '@/types/StackGmapServiceType'
import { Filter, SearchConfigField, SearchOption } from '@/types/utilTypes'
import { generateGmapRecord, initGmaps, isFeatureAccessible, sessionGet, sessionSet, toIsoDateFormat } from '@/utils'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { cloneDeep, debounce } from 'lodash'
import Vue from 'vue'
import QuantumChart from './QuantumChart.vue'


function generateInitialData() {
    return {
        records: [],
        markers: [] as GmapMarker[],
        totalRecords: 0,
        gmapRecords: [] as GmapRecord[]
    }
}

function generateInitialReportForm(source: string) {
    return {
        form: {
            source,
            name: '',
            settings: {} as SearchOption,
            isPublic: false,
            isOwned:  true
        },
        errors: {} as Record<string,string>,
        upsertDialog: false,
        deleteDialog: false,
        success: false,
        loading: false,
        deleteReport: {} as Report
    }
}

function generateBaseSearchOption() {
    return {
        'isAdvancedSearch': false,
        'timeZoneOffsetInMinutes': -420,
        'searchFilters': [],
        'keywords': '',
        'pageSize': 100,
        'pageIndex': 1, 
        'order': '',
        'hiddenCols': [],
        'headerOrder': {}
    }
}

export default Vue.extend({
    props:{
        api:{
            type: String
        },
        name:{
            type: String
        },
        quantumChartsAPI: {
            type: Object
        },
        exportAPI:{
            type: Object
        },
        pivotTableAPI:{
            type: Object
        },
        showTotal:{
            default: true,
            type: Boolean
        },
        fields:{
            type: Array
        },
        gmapOptions: {
            type: Object
        },
        keywordSearchPlaceholder: {
            default: '',
            type: String
        },
        importables: {
            type: Array
        },
        crudConfig: {
            type: Object,
            default: () => ({
                isUpdateable: false,
                isCreateable: false,
                isDeleteable: false,
                createPlaceHolderOverride: ''
            })
        },
        enableEditor: {
            default: false,
            type: Boolean
        }
    },
    name: 'Lookup',
    components: {
        AdvancedSearch,
        DataTable,
        GMapPin,
        QuantumChart,
        UnconfiguredGmapAlert,
        AdvancedSidebarSearch
    },
    async mounted() {
        this.mapsConfigLoading = true
        await initGmaps.call(this)
        this.mapsConfigLoading = false
        await this.getRecords()
        EventBus.$on(TRIGGER_SEARCH,  this.getRecords)
        this.resizeUntilLoaded()
        await this.getReports()
    },
    destroyed() {
        EventBus.$off()
    },
    created() {
        this.debouncedQuantumLoadData = debounce(this.quantumLoadData.bind(this), 500)
        const url = this.$route.query.q as string

        const session =  sessionGet(`${this.name}SearchOption`) as unknown as SearchOption

        const base:SearchOption = generateBaseSearchOption()

        let temp:any = url && {
            ...base,
            isAdvancedSearch: Boolean(url),
            searchFilters: url ? (JSON.parse(decodeURIComponent(url))).fields : [],
        }

        if(temp?.searchFilters && temp.searchFilters.length > 0) {
            if(temp.searchFilters.length > 4) {
                temp.searchFilters.length = 4
            }

            for(let searchFilter of (temp.searchFilters as Filter[])) {
                searchFilter.id = Date.now().toString() + Math.random()
                searchFilter.nonFilterFields = {
                    datePickerMenu: false,
                    dateValue: '',
                    type: 'string'
                }

                const foundField = (this.fields as SearchConfigField[]).find( (field) => field.name === searchFilter.field)
                if(foundField) {
                    searchFilter.nonFilterFields.type = foundField.type
                    searchFilter.nonFilterFields.dateValue = toIsoDateFormat(searchFilter.value)
                }
            }
        }

        temp = {
            ...base,
            ...session,
            ...temp,
        }
        
        this.searchOption = temp as SearchOption
        if(sessionGet(`${this.name}Tab`)) {
            this.tab = parseInt(sessionGet(`${this.name}Tab`))
        }
        const loadedReportForm = this.getLoadedReportFromSessionStorage()
        if(loadedReportForm) {
            this.reportForm.form = loadedReportForm
            this.loadedReportName = loadedReportForm.name
        }
    },
    data() {
        return {
            tab: 0,
            ...generateInitialData(),
            searchOption: {} as SearchOption,
            sessionTimeout: 10080,
            pageLoading: false,
            highlightText: '',
            abortController: new AbortController(),
            gmapRecordSetting: {} as GmapRecordSetting,
            gmapIconSetting: {} as StackGmapIconSetting,
            isMapConfigured: false,
            mapsConfigLoading: false,
            contentHeight: '100%',
            dataTableHeight: 0,
            editorView: false,
            reportForm: generateInitialReportForm(this.name),
            loadReportMenu: false,
            storageReportFormKey: `${this.name}Report`,
            reports: {
                owned: [] as Report[],
                public: [] as Report[],
                loading: false
            },
            loadedReportName: '',
            debouncedQuantumLoadData: () => undefined as any,
        }
    },
    watch: {
        searchOption:{
            handler(newValue){
                sessionSet(`${this.name}SearchOption`,newValue,this.sessionTimeout)
            },
            deep:true,
        },
        tab() {
            sessionSet(`${this.name}Tab`, this.tab, this.sessionTimeout)
            if(this.isMapView) {
                EventBus.$emit(ON_SHOW_MAP)
            }
            this.getRecords()
            this.resizeUntilLoaded()
        },
        editorView(newValue) {
            if(!newValue) {
                // To remove search filters that are hidden and retain searchfilters that are persistent
                const newSearchFilter = cloneDeep(this.searchOption.searchFilters).filter( filter => 
                    !this.searchOption.hiddenCols.includes(filter.field) || this.persistentFilters.includes(filter.field))

                if(newSearchFilter.length === 0 ) {
                    newSearchFilter.push({
                        id: Date.now().toString(),
                        field: '',
                        operator: '',
                        value: '',
                        logic: 'and',
                        nonFilterFields: {
                            dateValue: '',
                            datePickerMenu: false,
                            type: ''
                        }
                    })
                }
                this.searchOption.searchFilters = newSearchFilter

                this.getRecords()
            }
        }
    },
    methods: {
        resizeUntilLoaded() {
            const toolbarElement = document.querySelector('.v-toolbar')?.clientHeight ?? 0
            if(toolbarElement === 0) {
                setTimeout(() => {  this.resizeUntilLoaded()})
            } else {
                setTimeout(() => {  this.handleResize()})
            }
        },
        handleResize() {
            const toolbarElement = document.querySelector('.v-toolbar')?.clientHeight ?? 64
            const footerElement = document.querySelector('.v-footer')?.clientHeight ?? 36
            const tabs = document.querySelector('.v-tabs')?.clientHeight ?? 0
            const actionButtons = document.querySelector('.action-btns')?.clientHeight ?? 0
            const pagination = document.querySelector('.pagination')?.clientHeight ?? 0
            const keywordSearchContainer = document.querySelector('.keyword-search-container')?.clientHeight ?? 0
            
            let contentHeight = window.innerHeight - toolbarElement - footerElement

            this.contentHeight = this.$vuetify.breakpoint.xsOnly ? '90vh' : `${contentHeight}px`
            this.dataTableHeight = contentHeight - tabs - actionButtons - pagination - keywordSearchContainer - 50
        },
        onSearchOptionChange(value: Record<keyof SearchOption, any>, shouldSearch = false){
            this.searchOption =  cloneDeep({...this.searchOption, ...value})
            if(shouldSearch) {
                this.getRecords()
            }
            
        },
        addFilter([field,value]:string[]){
            this.searchOption.pageIndex = 1
            const url = this.$route.query.q as string ?? ''
            const decodedQuery = decodeURIComponent(url)

            //  @ts-ignore
            this.$refs.advancedSearch.addQuickFilter(field,value)
            const query =  JSON.stringify({fields:this.searchOption.searchFilters})

            if(decodedQuery !== query){ 
                this.$router.push({query:{
                    q:query
                }})
            }  
            
        },
        async exportRecords() {
            let selectedMarkers = this.markers.filter(o=>o.isSelected).map(o=>o.id).join(',')
            let searchOption = selectedMarkers.length > 0 ? {
                'isAdvancedSearch': true,
                'timeZoneOffsetInMinutes': -420,
                'searchFilters': [
                    {field: this.gmapOptions.exportIdField, operator: 'in', value: selectedMarkers, logic: 'and'}
                ],
                'keywords': '',
                'pageSize': 0,
                'pageIndex': 0,
                'order': ''
            } : null
            

            try {
                
                const res = await this.axios.post(
                    this.exportAPI.api,
                    searchOption? searchOption : this.searchOption,
                    {responseType: 'blob'}
                )
                const fileNamePrefix = this.loadedReportName || `${this.name}_Export`
                const fileName = `${fileNamePrefix}_${dayjs().format('YYYY-MM-DD_HHmmss')}.xlsx`

                saveAs(res.data,fileName)
            } catch (err) {
                console.log(err)
            }
        },
        async getRecords(){
            this.abortController.abort()
            this.pageLoading = true
            this.abortController = new AbortController()

            const initialData = generateInitialData()
            this.records = initialData.records
            this.markers = initialData.markers
            this.totalRecords = initialData.totalRecords
            this.gmapRecords = initialData.gmapRecords

            try {
                if(this.isQuantumChartView) {
                    this.debouncedQuantumLoadData()
                    this.pageLoading = false
                    return
                }
                
                const res = await this.axios.post(
                    this.api,
                    this.searchOption,
                    {
                        signal: this.abortController.signal
                    }
                )

                if(this.isMapView) {
                    this.gmapRecords = (res.data.records || []).map((record: any) => generateGmapRecord({
                        recordSetting: this.gmapRecordSetting, 
                        dynamicObj: record, 
                        id: record[this.gmapOptions.idFieldKey],
                        gmapIconSetting: this.gmapIconSetting,
                        enableUrl: !!record.property
                    }))

                } else {
                    this.records = res.data.records
                    
                    this.highlightText = this.searchOption.isAdvancedSearch ? '' : this.searchOption.keywords
                }
                
                this.totalRecords = res.data.totalRecords
                this.pageLoading = false
                
                if(this.searchOption.pageIndex > Math.ceil( this.totalRecords / this.searchOption.pageSize) && this.searchOption.pageIndex > 1) {
                    this.searchOption.pageIndex = 1
                    this.getRecords()
                }
            } catch (err) {
                if(!this.axios.isCancel(err)) {
                    this.pageLoading = false
                }
            }
        },
        async onSearch() {
            this.searchOption.pageIndex = 1
            await this.getRecords()
        },
        async onSaveReport() {
            this.reportForm.loading = true
            this.reportForm.errors = {}
            this.reportForm.success = false

            try{
                this.reportForm.form.settings = this.searchOption
                await reportService.upsertReport(this.reportForm.form)
                await this.getReports()
                this.reportForm.success = true
            }catch (e) {
                if(this.axios.isAxiosError(e) && e?.response?.status == 400 && e.response.data && e.response.data.errors) {
                    for (const [key, value] of Object.entries(e.response.data.errors)) {
                        this.reportForm.errors[key] = value as string
                    }
                    
                    this.reportForm.errors.General = 'There are errors that require your attention'
                } else {
                    this.reportForm.errors.General = 'We\'re sorry, but we can\'t process your request at this time. Please try again later.'
                }
            }
            this.reportForm.loading = false
            if(this.reportForm.success) {
                this.saveReportToSessionStorage()
            }
            
        },
        async getReports() {
            try{ 

                this.reports.loading = true
                this.reports.owned = []
                this.reports.public = []

                const resp = await reportService.getReports(this.name)
                resp.forEach( report => {
                    if(report.isOwned) {
                        this.reports.owned.push(report)
                    } else {
                        this.reports.public.push(report)
                    }
                    
                })

                this.reports.loading = false
            } catch(e) {
                this.reports.loading = false
            }
        },
        onSaveReportCancel() {
            let formCopy = this.reportForm.form
            let isSuccess = this.reportForm.success

            this.reportForm = generateInitialReportForm(this.name)
            if(isSuccess) {
                this.loadedReportName = formCopy.name
            }
            this.reportForm.form = formCopy
        },
        async onDeleteReport() {
            this.reportForm.loading = true
            this.reportForm.errors = {}
            this.reportForm.success = false

            try{ 
                await reportService.deleteReport(this.reportForm.deleteReport)
                this.reportForm.success = true
                await this.getReports()
            } catch(e) {
                this.reportForm.errors.General = 'There Are errors that require your attention'
            }
            this.reportForm.loading = false
        },
        onChooseDeleteReport(report: Report){
            this.loadReportMenu = false
            this.reportForm.deleteReport = report
            this.reportForm.deleteDialog = true
        },
        saveReportToSessionStorage() {
            sessionSet(this.storageReportFormKey, this.reportForm.form)
        },
        async onLoadReport(report: Report) {
            this.loadReportMenu = false
            this.reportForm.form = cloneDeep(report)
            const clonedSetting = cloneDeep(report.settings)
            if(!clonedSetting.headerOrder) {
                clonedSetting.headerOrder = {}
            }

            this.searchOption = clonedSetting
            this.saveReportToSessionStorage()
            this.loadedReportName = report.name
            await this.getRecords()
        },
        async onResetGrid() {
            this.clearReportState()
            await this.getRecords()
        },
        getLoadedReportFromSessionStorage() {
            return sessionGet(this.storageReportFormKey) as any
        },
        async onHideAllColumns() {
            this.searchOption.hiddenCols = this.fields.map((field:any) => field.alias)
            await this.getRecords()
        },
        async quantumDataLoad() {
            return (await this.axios.post(this.quantumChartsAPI.api, { ...this.searchOption, pageSize: -1 }, {
                signal: this.abortController.signal
            })).data
            
        },
        clearReportState(){
            this.reportForm = generateInitialReportForm(this.name)
            this.searchOption = generateBaseSearchOption()
            this.loadedReportName = ''
            sessionStorage.removeItem(this.storageReportFormKey)
        },
        quantumLoadData() {
            setTimeout(() => {
                const popConnQChart = this.$refs.propConnQChart as any
                if(popConnQChart && popConnQChart.$refs.qchart) 
                {
                    popConnQChart.$refs.qchart.loadData()
                }
            }, 500)
        }
    },
    computed: {
        isListView(): boolean {
            return this.tab === 0
        },
        isMapView(): boolean {
            return this.tab === 1
        },
        isQuantumChartView() : boolean {
            return this.tab === 2
        },
        canAccessQuantumCharts(): boolean {
            return this.quantumChartsAPI && isFeatureAccessible(this.quantumChartsAPI.requiredPermissions, this.$store.getters.user.permissions)
        },
        canUseExportFeature(): boolean {            
            return this.exportAPI && isFeatureAccessible(this.exportAPI.requiredPermissions, this.$store.getters.user.permissions) && this.fields.length !== this.searchOption.hiddenCols.length
        },
        persistentFilters(): string[] {
            return this.fields.filter((field: any) => field.showPersistently).map((field:any) => field.alias)
        }
    }
})
